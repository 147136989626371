import React from 'react';
import scubeContext from '../core/scubeContext';
import LocalizedStrings from 'react-localization';
import data from /* webpackChunkName: MyChunk */ './translate.json';

import * as enumTranslations from './enums';

//const data = React.lazy(() =>import(/* webpackChunkName: "Translation" */ './translate.json'));

const RenderTraslation = ({ text, path, altValue }) => {
  const handleClick = (event) => {
    if (event.shiftKey || event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
      //alert('translate :' + path);
      //console.log('t.path: ' + path + '     ' + altValue);

      if (translationService.developmentAction) {
        translationService.developmentAction({ text, path, altValue, lang: translationService.language });
      }
    }
  };
  return (
    <span style={{ border: '1px solid yellow' }} onClick={handleClick}>
      {text}
    </span>
  );
};

const translationService = {
  items: [],
  language: scubeContext.language,
  t: data[scubeContext.language],
  te: enumTranslations.en,
  developmentAction: undefined,
  developmentHelpAction: undefined,
};

const rekurze = (obj, resultObj, path, altlocStr) => {
  if (!obj) return;

  //https://www.measurethat.net/Benchmarks/Show/363/0/getownpropertynames-vs-loop-vs-cached-property-names
  //zlepsit 3x
  Object.getOwnPropertyNames(obj).forEach((property) => {
    //console.log(property);

    if (property.startsWith('$')) {
      return;
    }

    let value = obj[property];
    let altValue = undefined;

    if (altlocStr && altlocStr[property]) {
      altValue = altlocStr[property];
    }

    let curpath = path + '.' + property;
    ///console.log("val:" + value);

    if (typeof value === 'object') {
      let tmpObj = {};

      rekurze(value, tmpObj, curpath, altValue);

      resultObj[property] = tmpObj;
    } else {
      //console.log(value);
      //console.log(typeof( property));
      if (typeof value === 'string') {
        resultObj[property] = <RenderTraslation text={value} path={curpath} altValue={altValue} />;
      }
    }
  });
};

//window.dodebug = true;

const createLocalizedStrings = (language) => {
  if (!window.dodebug) {
    //&& 1 == 2
    const locStr = new LocalizedStrings(data);
    locStr.setLanguage(language);

    return locStr;
  } else {
    const locStr = data[language];
    let altlocStr = data[language];

    if (language == 'cs') {
      altlocStr = data.en;
    }
    if (language == 'en') {
      altlocStr = data.cs;
    }

    //locStr.signUp.authenticate.signIn.Title = <RenderTraslation text={locStr.signUp.authenticate.signIn.Title} />;
    let resultObj = {};

    rekurze(locStr, resultObj, 't', altlocStr);

    return resultObj;
  }
};

const setTranslations = (language) => {
  translationService.language = language;
  translationService.t = createLocalizedStrings(language);
  translationService.te = enumTranslations[language];
  scubeContext.language = language;
  scubeContext.region = language;

  reloadFreshworksWidget(language);
};

const reloadFreshworksWidget = (language) => {
  if (window.FreshworksWidget) {
    //window.FreshworksWidget("switchLanguage", language);

    window.FreshworksWidget('destroy');
    window.fwSettings = {
      widget_id: 77000001666,
      locale: language,
    };

    // window.FreshworksWidget = undefined;
    //
    // let aa =function () {
    //   if ('function' != typeof window.FreshworksWidget) {
    //     let n = function () {
    //       n.q.push(arguments);
    //     };
    //     n.q = [];
    //     window.FreshworksWidget = n;
    //   }
    // };
    // aa();
    //window.FreshworksWidget();
    window.FreshworksWidget('boot');
  }
};

translationService.subscribe = function (func) {
  for (let i = 0; i < translationService.items.length; i++) {
    if (translationService.items[i] === func) {
      return;
    }
  }

  translationService.items.push(func);
};

translationService.unSubscribe = function (func) {
  const index = translationService.items.indexOf(func);
  if (index > -1) {
    translationService.items.splice(index, 1);
  }
};

translationService.publish = function (newVal) {
  for (let i = 0; i < translationService.items.length; i++) {
    translationService.items[i](newVal);
  }
};

translationService.setLanguage = function (language) {
  setTranslations(language);
  const result = {
    t: translationService.t,
    te: translationService.te,
    language: translationService.language,
  };

  translationService.publish(result);
};

translationService.setLanguage(scubeContext.language);

document.onkeyup = function (ev) {
  ev.preventDefault();

  if (ev.shiftKey && ev.ctrlKey && (ev.key == 'T' || ev.key == 'Q')) {
    //alert ("Shift key is pressed.");
    window.dodebug = true;

    let originalLang = scubeContext.language;
    if (scubeContext.language == 'cs') {
      translationService.setLanguage('cs');
      translationService.setLanguage(originalLang);
    } else {
      translationService.setLanguage('en');
      translationService.setLanguage(originalLang);
    }
  }
  if (ev.shiftKey && ev.ctrlKey && ev.key == 'Y') {
    //alert ("Shift key is pressed.");
    window.dodebug = false;

    let originalLang = scubeContext.language;
    if (scubeContext.language == 'cs') {
      translationService.setLanguage('en');
      translationService.setLanguage(originalLang);
    } else {
      translationService.setLanguage('cs');
      translationService.setLanguage(originalLang);
    }
  }
  ev.preventDefault();
};

export default translationService;
