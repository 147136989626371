export default class sceneContext {
  constructor() {
    this.onAnimationFinished = undefined;
    this.invokeAnimationStart = undefined;
    this.invokeFadeToAnimation = undefined;
    this.invokeSetSize = undefined;

    this.amy3DHolder = undefined; //web gl scene and models
    this.curSequence = undefined;

    this.eventListeners = [];
  }

  startSequence(sequence) {
    this.curSequence = sequence; //zatial iba jedna sequence v jednom case
    //console.log(this);
    sequence.onStart(this);
  }
  fadeToAnimation(animationName) {
    //console.log(this);
    this.invokeFadeToAnimation(animationName);
  }
  startEffect(effect) {
    effect.onStart(this);
  }
  startCamera(camera) {
    camera.onStart(this);
  }
  animationFinished(animationName) {
    this.curSequence.onAnimationFinished(this, animationName);
  }
  setSize(width, height) {
    this.invokeSetSize(width, height);
  }

  invokeCameraFinished(camera) {
    this.curSequence.onCameraFinished(this, camera);
  }
  //invoke events
  invokeEffectFinished(effect) {
    this.curSequence.onEffectFinished(this, effect);
  }
  //process event in amy webgl
  invokeEvent(sceneEvent) {
    if (this.currentScene && this.curSequence) {
      this.curSequence.onEvent(this, sceneEvent);
      this.currentScene.onEvent(this, sceneEvent);
    }
  }
  //send event outside of amy
  pushEvent(sceneEvent) {
    for (let i = 0; i < this.eventListeners.length; i++) {
      this.eventListeners[i](sceneEvent);
    }
  }
}

export const sceneEngine = {
  curSceneContext: undefined,
  init(scene, amyModel) {
    scene.modelName = amyModel;
    this.curSceneContext = new sceneContext();
    this.curSceneContext.currentScene = scene;
  },
  startScene() {
    //console.log('scene started');
    this.curSceneContext.currentScene.onStart(this.curSceneContext);
  },
  stopScene() {
    this.curSceneContext.currentScene.onStop(this.curSceneContext);
  },
  pushEvent(sceneEvent) {
    if (this.curSceneContext) {
      this.curSceneContext.invokeEvent(sceneEvent);
    }
  },

  animationFinished(animationName) {
    this.curSceneContext.animationFinished(animationName);
  },

  setSize(width, height) {
    this.curSceneContext.setSize(width, height);
  },

  subscribeListen(eventListener) {
    this.curSceneContext.eventListeners.push(eventListener);
  },

  unSubscribeListen(eventListener) {
    const index = this.curSceneContext.eventListeners.indexOf(eventListener);
    if (index > -1) {
      this.curSceneContext.eventListeners.splice(index, 1);
    }
  },
};
