/** UI Theme */
import translationService from '../../translations/translationService';
const E_UI_THEME = 
{
    DARK: 'd',
    LIGHT: 'l',

}

const E_UI_THEME_Collection = [
    { id: E_UI_THEME.DARK, title: 'd' },
    { id: E_UI_THEME.LIGHT, title: 'l' },
];

E_UI_THEME.getEnum = () => {
    return E_UI_THEME_Collection.map(x => (
        { id: x.id, title: translationService.te.enums.E_UI_THEME[x.id] }
    ))
}

export { E_UI_THEME, E_UI_THEME_Collection } ;
