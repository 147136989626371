import { cameraDefault } from './cameras';
import { effectExitWaiting, effectIntro } from './effects';
import { sceneEventTypes } from '../sceneEvents';
import scubeContext from '../../../core/scubeContext';

const devConsoleLog = (message) => {
  if (window.isDevelopment) {
    //console.log(message);
  }
};

export const sequenceHelp = {
  onStart(context) {
    context.walkTimes = 2;
    context.idle = true;
    context.sleep = false;

    context.startCamera(cameraDefault);
    context.startEffect(effectIntro);
    context.fadeToAnimation('Walking');

    //context.setSize(600,200);
  },

  onFinish(context) {
    //stop events
    //stop animations
  },

  selectIdle() {
    let animations = ['Idle', 'Happy', 'Tablet', 'Bashful'];
    let order = Math.floor(Math.random() * Math.floor(animations.length));
    return animations[order];
  },

  selectIntermezzo() {
    let animations = [];
    if (scubeContext.tenantType === 'physical') {
      animations = [
        'Secret',
        'Yawn',
        'Thankful',
        'Reacting',
        'Looking',
        'Jump',
        'Climbing',
        'ArmsStretch',
        'SillyDancing',
        'RumbaDancing',
        'Agreeing',
        'Looking2',
        'Macarena',
        'Salsa',
        'Pointing',
        'ShoulderRubbing',
        'LookingBehind',
        'No',
        'Yes',
      ];
    } else {
      animations = [
        'Secret',
        'Thankful',
        'Reacting',
        'Looking',
        'ArmsStretch',
        'Agreeing',
        'Looking2',
        'Pointing',
        'ShoulderRubbing',
        'LookingBehind',
        'No',
        'Yes',
      ];
    }
    let order = Math.floor(Math.random() * Math.floor(animations.length));
    return animations[order];
  },

  onAnimationFinished(context, animationName) {
    devConsoleLog('onAnimationFinished:' + animationName);

    // waiting
    if (context.sleep) {
      // DAVID STOP
      return;
    }

    // intro walking
    if (animationName === 'Walking') {
      if (context.walkTimes > 0) {
        //devConsoleLog('onAnimationFinished: context.walkTimes > 0' + animationName);

        context.walkTimes -= 1;
      } else {
        //devConsoleLog('onAnimationFinished: else context.walkTimes > 0' + animationName);

        // run first idle
        context.fadeToAnimation('Greeting');
        context.idle = false;
      }
    } else {
      if (context.idle) {
        //devConsoleLog('onAnimationFinished: context.idle' + animationName);

        let anim = this.selectIntermezzo();
        context.fadeToAnimation(anim);
        if (anim === 'Yes') {
          context.startEffect(effectExitWaiting);
        }
        context.idle = false;
      } else {
        //devConsoleLog('onAnimationFinished: else context.idle' + animationName);

        context.fadeToAnimation(this.selectIdle());
        context.idle = true;
      }
    }
  },

  onEffectFinished(context, effect) {
    if (effect === effectExitWaiting && !context.sleep) {
      context.startEffect(effectIntro);
    }
  },

  onCameraFinished(context, camera) {},

  onEvent(context, sceneEvent) {
    // devConsoleLog("chat event received");
    //devConsoleLog(sceneEvent);
    if (
      sceneEvent.eventType === sceneEventTypes.ReceivedChatButtonSet ||
      sceneEvent.eventType === sceneEventTypes.ReceivedQuestionnaireAmyText ||
      sceneEvent.eventType === sceneEventTypes.ReceivedChatQuestion
    ) {
      if (!context.walkTimes) {
        let animations = ['HandRising', 'Waving', 'Waving2'];
        let order = Math.floor(Math.random() * Math.floor(animations.length));
        context.fadeToAnimation(animations[order]);
      }
    }

    if (sceneEvent.eventType === sceneEventTypes.SentChatAnswer) {
      //context.startEffect(effectExitWaiting);
    }

    if (sceneEvent.eventType === sceneEventTypes.ReceivedAmyEvent) {
      if (sceneEvent.eventSubType === 'end-session') {
        context.sleep = true;
        context.startEffect(effectExitWaiting);
      }
    }
  },
  isRecurrent: true,
};

export const sceneHelp = {
  id: 'sceneHelp',
  name: 'help', //development field only
  modelName: 'amy_psychologist.glb',
  onStart: (context) => {
    context.startSequence(sequenceHelp);
  },
  onStop: (context) => {},

  onFinish: (context) => {},
  onSequenceFinished: (sequenceName) => {},
  onEffectFinished: (effectName) => {},
  onEvent: (context, sceneEvent) => {},
  sequences: [sequenceHelp],
  amySettings: {
    particlesHitArea: false,
    particlesRandom: 30.0, //1.5
    particlesDepth: 2, //6 //6.06
    particlesXYMove: true, //true
    particlesBW: false,
    particlesSquare: false,
    particlesSize: 0.5, //4.0, //3.2
    particlesSmooth: 0.9, // //0.3
    particlesShift: 0,
    matrix: 128, //128, // 100
    planeRotate: 0,
  },
};
