import React, { useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import scubeContext from '../../core/scubeContext';
import Particles from './particles';
import { sceneEngine } from './sceneEngine';
import DeviceType from '../../core/enums/DeviceType';
import AmyMode from '../chats/amyMode';
import AmyPosition from '../chats/amyPosition';
import AmyAspectRatio from '../chats/amyAspectRatio';
import {
  AnimationMixer,
  BoxGeometry,
  Clock,
  Color,
  DirectionalLight,
  DoubleSide,
  HemisphereLight,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  PerspectiveCamera,
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget,
} from 'three';

const debugMode = false;

const initParticles = (sceneHolder, ratio) => {
  sceneHolder.particles = new Particles(sceneHolder);
  sceneHolder.scene.add(sceneHolder.particles.container);

  sceneHolder.particles.init(sceneHolder.bufferTexture, ratio);
  //sceneHolder.particles.init(sceneHolder.bufferDepthTexture);
};

const reloadModel = (result) => {
  let self = result;
  result.amySettings = sceneEngine.curSceneContext.currentScene.amySettings;

  if (self.curAnimationModel) {
    self.bufferScene.remove(self.curAnimationModel);
  }

  function traverseMaterials(object, callback) {
    object.traverse((node) => {
      if (!node.isMesh) return;
      const materials = Array.isArray(node.material) ? node.material : [node.material];
      materials.forEach(callback);
    });
  }

  const updateTextureEncoding = (curContent) => {
    const encoding = sRGBEncoding; //this.state.textureEncoding === 'sRGB' ? sRGBEncoding : LinearEncoding;
    traverseMaterials(curContent, (material) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  };

  let loader = new GLTFLoader();
  let modelPath = '/models/' + sceneEngine.curSceneContext.currentScene.modelName + '?v=' + window.staticfilesversion;

  loader.load(modelPath, function (object) {
    for (let i = 0; i < object.animations.length; i++) {
      //var clip2 = AnimationClip.parse(AnimationClip.toJSON(clip));
      var curAnim = object.animations[i];
      if (curAnim.duration > 10.0) {
        curAnim.duration = 10.0;
        if (curAnim.tracks) {
          //console.log('skratene');
          curAnim.tracks.forEach((track) => track.trim(0, 10.0));
        }
      }
    }

    //normalize model and materials
    updateTextureEncoding(object.scene);

    object.scene.scale.set(100, 100, 100); // scale here
    self.bufferScene.add(object.scene);

    self.mixer = new AnimationMixer(object.scene);

    self.mixer.addEventListener('loop', onLoopFinished);

    function onLoopFinished(event) {
      sceneEngine.animationFinished(event.action._clip.name);
    }

    var newMaterial = new MeshBasicMaterial({ color: 0xff00ff });

    object.scene.traverse(function (child) {
      child.frustumCulled = false;
    });

    object.scene.traverse(function (child) {
      if (child.isMesh) {
        // TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
        // roughnessMipmapper.generateMipmaps( child.material );
        //console.log(child);
        //console.log(child.material);
        //child.material = newMaterial;

        child.material.side = DoubleSide; //DoubleSide;
        //child.material.transparent = true;
        //child.material.opacity = 0.1;
      }
    });

    self.modelAnimations = [];
    self.modelAnimationsNamed = [];

    for (let i = 0; i < object.animations.length; i++) {
      let action = self.mixer.clipAction(object.animations[i]);
      //action.setDuration(1.0);

      self.modelAnimations.push(action);
      self.modelAnimationsNamed[action._clip.name] = action;
    }

    sceneEngine.curSceneContext.invokeFadeToAnimation = (newAnimationName) => {
      if (!self.modelAnimationsNamed[newAnimationName]) {
        //console.log('missing animation : ' + newAnimationName);
        return;
      }

      var newAction = self.modelAnimationsNamed[newAnimationName];

      setWeight(newAction, 1);
      newAction.time = 0;

      if (self.curAction != null) {
        self.curAction.crossFadeTo(newAction, 0.35, true);
      }

      self.curAction = newAction;
      //newAction.halt(1.0);
      newAction.play();
    };

    sceneEngine.startScene();
  });
};

const CreateScene = (width, height, themeId) => {
  let result = {
    themeId: themeId,
  };

  sceneEngine.curSceneContext.amy3DHolder = result;

  result.bufferSize = 128; //64,128

  //result.scene = new Scene();

  result.scene = new Scene();

  // camera
  //result.camera = new OrthographicCamera(-100,100, 100,-100,1,1000);
  result.camera = new PerspectiveCamera(50, width / height, 1, 10000);
  //result.camera.position.z = 300;
  result.camera.position.z = 300;

  // renderer
  result.renderer = new WebGLRenderer({ antialias: false, alpha: true }); //, alpha: true - transparency

  result.amyClearColor = new Color(0x010000);
  result.matrixClearColor = new Color(0x010000);
  //result.renderer.setClearColor(result.amyClearColor, 0);
  result.renderer.setClearAlpha(0.0);
  result.renderer.outputEncoding = sRGBEncoding;

  //result.bufferCamera.aspect = 1.0;

  if (debugMode) result.renderer.setSize(result.bufferSize, result.bufferSize);

  // Create a different scene to hold our buffer objects
  result.bufferScene = new Scene();

  result.bufferTexture = new WebGLRenderTarget(result.bufferSize, result.bufferSize, {
    minFilter: LinearFilter,
    magFilter: NearestFilter,
    //depthTexture : result.bufferDepthTexture
  });

  //result.bufferTexture.stencilBuffer = true;
  //result.bufferTexture.depthBuffer = true;
  //result.bufferTexture.depthTexture = new DepthTexture();
  // result.bufferTexture.depthTexture.format = DepthFormat;
  // result.bufferTexture.depthTexture.type = UnsignedShortType;

  result.bufferTexture.encoding = sRGBEncoding;

  result.bufferCamera = new PerspectiveCamera(20, width / height, 0.1, 2000);

  //result.bufferCamera = new OrthographicCamera(-100,100, 100,-100,1,1000);

  result.bufferCamera.position.set(0, 170, 400.0);
  result.bufferCamera.lookAtPosition = new Vector3(0, 120, 0);

  result.bufferCamera.lookAt(result.bufferCamera.lookAtPosition);

  result.bufferCamera.updateProjectionMatrix();

  //var hemisphereLight = new HemisphereLight(0x404040, 0x303030);
  var hemisphereLight = new HemisphereLight(0x303030, 0x000000);

  hemisphereLight.position.set(0, 400, 0);
  result.bufferScene.add(hemisphereLight);

  var dlight = new DirectionalLight(0xffffff, 1.0);
  dlight.position.set(100, 200, 300);
  dlight.castShadow = true;
  dlight.shadow.camera.top = 50;
  dlight.shadow.camera.bottom = -50;
  dlight.shadow.camera.left = -50;
  dlight.shadow.camera.right = 50;
  result.bufferScene.add(dlight);

  //result.bufferScene.add(new DirectionalLightHelper(dlight, 5));

  result.bufferMixer = undefined;

  //temporary box model
  const geometry = new BoxGeometry(1, 1, 1);
  const material = new MeshBasicMaterial({ color: 0xff00ff });
  const cube = new Mesh(geometry, material);

  //result.bufferScene.add(cube);

  reloadModel(result);
  //init particles
  initParticles(result, width / height);

  // clock
  result.clock = new Clock(true);

  return result;
};

function setWeight(action, weight) {
  action.enabled = true;
  action.setEffectiveTimeScale(1);
  action.setEffectiveWeight(weight);
}

const Amy3D = ({ amyScene, amyModel, className, dimensions, amyTheme }) => {
  const { useRef, useEffect, useState } = React;
  const mount = useRef(null);
  const [isAnimating] = useState(true);
  const controls = useRef(null);

  useEffect(() => {
    //console.log('init amy webgl');

    let width = dimensions.width;
    let height = dimensions.height;
    let frameId;

    sceneEngine.init(amyScene, amyModel);
    const sceneHolder = CreateScene(width, height, amyTheme);

    //init resize
    sceneEngine.curSceneContext.invokeSetSize = (width, height) => {
      handleResize(width, height);
    };

    let renderCounter = 0;

    const renderScene = () => {
      renderCounter++;

      if (renderCounter % 5 !== 0) return;

      let delta = sceneHolder.clock.getDelta();
      //update scene parts
      if (sceneHolder.mixer) sceneHolder.mixer.update(delta);
      if (sceneHolder.particles) sceneHolder.particles.update(delta);

      if (debugMode) {
        sceneHolder.renderer.setClearColor(sceneHolder.amyClearColor, 1);
        sceneHolder.renderer.render(sceneHolder.bufferScene, sceneHolder.bufferCamera);
      } else {
        sceneHolder.renderer.setClearColor(sceneHolder.amyClearColor, 1);
        sceneHolder.renderer.setRenderTarget(sceneHolder.bufferTexture);
        sceneHolder.renderer.clear();
        sceneHolder.renderer.render(sceneHolder.bufferScene, sceneHolder.bufferCamera);

        //console.log('render');
        // Finally, draw to the screen
        width = dimensions.width; //mount.current.clientWidth;
        height = dimensions.height; //mount.current.clientHeight;
        //sceneHolder.renderer.setSize(width, height);

        sceneHolder.renderer.setClearColor(sceneHolder.matrixClearColor, 0);
        sceneHolder.renderer.setRenderTarget(null);
        sceneHolder.renderer.clear();
        sceneHolder.renderer.render(sceneHolder.scene, sceneHolder.camera);
      }
    };

    const handleResize = (width, height) => {
      //console.log('amy webgl handleResize');

      sceneHolder.renderer.setSize(width, height);

      sceneHolder.bufferCamera.aspect = width / height;
      sceneHolder.bufferCamera.updateProjectionMatrix();

      sceneHolder.camera.aspect = width / height;
      sceneHolder.camera.updateProjectionMatrix();

      renderScene();
    };

    const animate = () => {
      renderScene();
      frameId = window.requestAnimationFrame(animate);
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    };

    mount.current.appendChild(sceneHolder.renderer.domElement);

    start();

    controls.current = { start, stop };

    handleResize(dimensions.width, dimensions.height);

    return () => {
      stop();
      if (mount.current) {
        mount.current.removeChild(sceneHolder.renderer.domElement);
      }

      // sceneHolder.scene.remove(cube);
      // sceneHolder.geometry.dispose();
      // sceneHolder.material.dispose();
    };
  }, [
    amyScene,
    amyTheme,
    dimensions.aspectRatio,
    dimensions.isDownScaled,
    dimensions.width,
    dimensions.height,
    amyModel,
  ]);

  useEffect(() => {
    if (isAnimating) {
      controls.current.start();
    } else {
      controls.current.stop();
    }
  }, [isAnimating]);

  return (
    <div
      className={className}
      ref={mount}
      //onClick={() => setAnimating(!isAnimating)}
      style={{ width: dimensions.width, height: dimensions.height }}
    />
  );
};

Amy3D.defaultProps = {
  sceneProps: { deviceType: DeviceType.Desktop, mode: AmyMode.FullBody, position: AmyPosition.Center },
  dimensions: { width: 801, height: 450, aspectRatio: AmyAspectRatio.Rect_16x9, isDownScaled: false },
  amyTheme: scubeContext.theme,
};

export default Amy3D;
