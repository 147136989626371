/* eslint import/no-webpack-loader-syntax: off */


import TouchTexture from './touchTexture';
import { Power1, TweenLite } from 'gsap';
import { E_UI_THEME } from '../../core/enums/E_UI_THEME';
import {
  BoxGeometry,
  BufferAttribute,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  RawShaderMaterial,
  Vector2
} from 'three';


export default class Particles {
  constructor(webgl) {
    this.webgl = webgl;
    this.container = new Object3D();
  }

  init(myTextureTarget, ratio) {
    this.myTextureTarget = myTextureTarget;

    //console.log(this.webgl.amySettings);

    this.width = Math.floor(this.webgl.amySettings.matrix * ratio) + this.webgl.amySettings.particlesShift; //1.78
    this.height = Math.floor(this.webgl.amySettings.matrix) + this.webgl.amySettings.particlesShift;

    //console.log({width:this.width, height:this.height});

    this.initPoints(!this.useVideo);
    this.initHitArea();
    this.initTouch();
    this.resize();
    this.show();
  }

  initPoints() {
    this.numPoints = this.width * this.height;

    //console.log({ numPoints: this.numPoints, width: this.width, height: this.height });

    let numVisible = this.numPoints;
    let threshold = 0;
    let originalColors;

    const uniforms = {
      uTime: { value: 0 },
      uRandom: { value: this.webgl.amySettings.particlesRandom },
      uDepth: { value: this.webgl.amySettings.particlesDepth },
      uSize: { value: this.webgl.amySettings.particlesSize },
      uSmooth: { value: this.webgl.amySettings.particlesSmooth },
      uXYMove: { value: this.webgl.amySettings.particlesXYMove },
      uSquare: { value: this.webgl.amySettings.particlesSquare },
      uBWColor: { value: this.webgl.amySettings.particlesBW },
      uTextureSize: { value: new Vector2(this.width, this.height) },
      uTexture: { value: this.texture },
      uTouch: { value: null },
    };

    uniforms.uTexture = { value: this.myTextureTarget.texture };
    // uniforms.tDepth = { value: this.myTextureTarget.depthTexture };
    //
    // uniforms.cameraNear = { value: this.webgl.camera.near };
    // uniforms.cameraFar = { value: this.webgl.camera.far };

    // console.log('fragment vypis');
    // console.log(particleFragment);

    const material = new RawShaderMaterial({
      uniforms,
      vertexShader: require('!!raw-loader!../amyWebGL/shaders/particle.vert').default,
      fragmentShader: require('!!raw-loader!../amyWebGL/shaders/particle.frag').default,
      depthTest: false,
      transparent: true,
      // blending: AdditiveBlending
    });

    //material.encoding = sRGBEncoding;

    if (this.webgl.themeId == E_UI_THEME.LIGHT) {
      material.defines['MR_WHITE'] = true;
    }
    if (this.webgl.themeId == E_UI_THEME.DARK) {
      material.defines['MR_BLACK'] = true;
    }

    //material.defines["MR_WHITE"] = true;
    //material.defines["MR_BLACK"] = true;

    const geometry = new InstancedBufferGeometry();

    // positions
    const positions = new BufferAttribute(new Float32Array(4 * 3), 3);
    positions.setXYZ(0, -0.5, 0.5, 0.0);
    positions.setXYZ(1, 0.5, 0.5, 0.0);
    positions.setXYZ(2, -0.5, -0.5, 0.0);
    positions.setXYZ(3, 0.5, -0.5, 0.0);
    geometry.setAttribute('position', positions);

    // uvs
    const uvs = new BufferAttribute(new Float32Array(4 * 2), 2);
    uvs.setXYZ(0, 0.0, 0.0);
    uvs.setXYZ(1, 1.0, 0.0);
    uvs.setXYZ(2, 0.0, 1.0);
    uvs.setXYZ(3, 1.0, 1.0);
    geometry.setAttribute('uv', uvs);

    // index
    geometry.setIndex(new BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1));

    const indices = new Uint16Array(numVisible);
    const offsets = new Float32Array(numVisible * 3);
    const angles = new Float32Array(numVisible);

    for (let i = 0, j = 0; i < this.numPoints; i++) {
      //MR if (discard && originalColors[i * 4 + 0] <= threshold) continue;

      offsets[j * 3 + 0] = i % this.width;
      offsets[j * 3 + 1] = Math.floor(i / this.width);

      indices[j] = i;

      angles[j] = Math.random() * Math.PI;

      j++;
    }

    geometry.setAttribute('pindex', new InstancedBufferAttribute(indices, 1, false));
    geometry.setAttribute('offset', new InstancedBufferAttribute(offsets, 3, false));
    geometry.setAttribute('angle', new InstancedBufferAttribute(angles, 1, false));

    this.object3D = new Mesh(geometry, material);

    if (this.planeRotate !== undefined) this.object3D.rotation.y = this.planeRotate;

    this.container.add(this.object3D);

    //temporary box model
    const geometryBox = new BoxGeometry(1, 1, 1);
    const materialBox = new MeshBasicMaterial({ color: 0xff00ff });
    const cubeBox = new Mesh(geometryBox, materialBox);

    //this.container.add(cubeBox);
  }

  initTouch() {
    // create only once
    if (!this.touch) this.touch = new TouchTexture(this);
    this.object3D.material.uniforms.uTouch.value = this.touch.texture;
  }

  initHitArea() {
    const geometry = new PlaneGeometry(this.width, this.height, 1, 1);
    const material = new MeshBasicMaterial({ color: 0x000000, wireframe: true, depthTest: false });
    material.visible = false;
    this.hitArea = new Mesh(geometry, material);
    this.container.add(this.hitArea);
  }

  addListeners() {
    this.handlerInteractiveMove = this.onInteractiveMove.bind(this);

    this.webgl.interactive.addListener('interactive-move', this.handlerInteractiveMove);
    this.webgl.interactive.objects.push(this.hitArea);
    this.webgl.interactive.enable();
  }

  removeListeners() {
    this.webgl.interactive.removeListener('interactive-move', this.handlerInteractiveMove);

    const index = this.webgl.interactive.objects.findIndex((obj) => obj === this.hitArea);
    this.webgl.interactive.objects.splice(index, 1);
    this.webgl.interactive.disable();
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update(delta) {
    if (!this.object3D) return;
    if (this.touch) this.touch.update();

    if (this.planeRotate !== undefined) {
      // MR
      //this.object3D.rotation.x = -this.planeRotate;
      this.object3D.rotation.y = this.planeRotate;
    }

    this.object3D.material.uniforms.uTime.value += delta;
  }

  show(time = 1.0) {
    // reset
    //TweenLite.fromTo(this.object3D.material.uniforms.uSize, time, { value: 0.5 }, { value: 1.5 });
    // TweenLite.to(this.object3D.material.uniforms.uRandom, time, { value: 2.0 });
    //TweenLite.fromTo(this.object3D.material.uniforms.uDepth, time * 1.5, { value: 40.0 }, { value: 4.0 });
    //vratit
    //this.addListeners();
  }

  hide(_destroy, time = 0.8) {
    return new Promise((resolve, reject) => {
      TweenLite.to(this.object3D.material.uniforms.uRandom, time, {
        value: 5.0,
        onComplete: () => {
          if (_destroy) this.destroy();
          resolve();
        },
      });
      TweenLite.to(this.object3D.material.uniforms.uDepth, time, { value: -20.0, ease: Power1.easeIn });
      TweenLite.to(this.object3D.material.uniforms.uSize, time * 0.8, { value: 0.0 });

      this.removeListeners();
    });
  }

  destroy() {
    if (!this.object3D) return;

    this.object3D.parent.remove(this.object3D);
    this.object3D.geometry.dispose();
    this.object3D.material.dispose();
    this.object3D = null;

    if (!this.hitArea) return;

    this.hitArea.parent.remove(this.hitArea);
    this.hitArea.geometry.dispose();
    this.hitArea.material.dispose();
    this.hitArea = null;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
    if (!this.object3D) return;

    const scale = 256.0 / this.webgl.amySettings.matrix;
    this.object3D.scale.set(scale, scale, 1);

    //moved from webgl component
    //this.webgl.fovHeight = 2 * Math.tan((this.webgl.camera.fov * Math.PI) / 180 / 2) * this.webgl.camera.position.z;

    //console.log(this.webgl.fovHeight);

    //const scale = this.webgl.fovHeight / this.height;
    //this.object3D.scale.set(scale, scale, 1);
    //TODO: set valid scale David
    //this.object3D.scale.set(4.0, 4.0, 1);
    //this.object3D.scale.set(2.0, 2.0, 1);
    //this.object3D.scale.set(8.0, 8.0, 1);
    //this.hitArea.scale.set(scale, scale, 1);
  }

  onInteractiveMove(e) {
    const uv = e.intersectionData.uv;

    if (this.touch) this.touch.addTouch(uv);
  }
}
