const scubeContext = {
  ...window.scubeUserContext,
  language: window.language ?? 'cs',
  region: window.language ?? 'cs',
  theme: window.theme ?? 'd',
  tenantsList: [],
  fbac: {},
  isTest: false,
  userEmail: '',
  userKey: undefined,
  tenantType: window.tenantType ?? 'undefined',
  appType: undefined,
  isAuth: false,
};

scubeContext.getApiPath = function (apiUrl) {
  //ui react/:tenant/somthing

  // /:tenant/somthing
  const result = '/' + scubeContext.TenantUriName + '/' + apiUrl;

  return result;
};



//getClientUrlSegment

scubeContext.getUrlByClientType = function (clientId, clientType, relativePath) {
  let uriClientType = undefined;
  if (clientType === 'c' || clientType === undefined) {
    uriClientType = 'c';
  }
  if (clientType ===  'companyCandidate') {
    uriClientType ='cc';
  }
  if (clientType === 'companyClient' ) {
    uriClientType = 'ce';
  }
  if (clientType ===  'psychologistClient') {
    uriClientType = 'pc';
  }
  if (clientType === 'physicalClient' ) {
    uriClientType = 'pp';
  }

  let result = `/${uriClientType}/${clientId}`;

  if(!relativePath)
  {
    return result;
  }

  relativePath = relativePath.startsWith('/') ? relativePath : `/${relativePath}`;

  result = result + relativePath;

  return result;
};

scubeContext.getLiteClientUrlSegment = function () {
  const result = '/section/' + scubeContext.userKey;
  return result;
};

scubeContext.getAbsoluteUrlForTenant = function (relativePath) {
  const result = '/' + scubeContext.TenantUriName + relativePath;

  return result;
};


scubeContext.getWorkspaceApiPath = function (url) {
  //react/:tenant/somthing
};

scubeContext.getRouteSegmentPath = function (url) {
  return '/' + url;
};

scubeContext.onSetTenant = undefined;
scubeContext.setTenant = function (tenantName) {
  scubeContext.onSetTenant(tenantName);
};

scubeContext.onRedirectToTenant = undefined;
scubeContext.redirectToTenant = function (tenantUri) {
  scubeContext.onRedirectToTenant(tenantUri);
};
scubeContext.tenantsList = [];

scubeContext.onSignOutForGuestSession = undefined;
scubeContext.signOutForGuestSession = function (guestUrl) {
  scubeContext.onSignOutForGuestSession(guestUrl);
};

export default scubeContext;
