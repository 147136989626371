

export enum sceneEventTypes {
  None='None',
  ReceivedChatQuestion='ReceivedChatQuestion',
  ReceivedChatButtonSet='ReceivedChatButtonSet',
  ReceivedChatText='ReceivedChatText',
  ReceivedQuestionnaireAmyText='ReceivedQuestionnaireAmyText',
  ReceivedQuestionnaireOptions='ReceivedQuestionnaireOptions',
  ReceivedAmyEvent='ReceivedAmyEvent',

  SentChatAnswer='SentChatAnswer'
}

export class sceneEvent{
  eventType:sceneEventTypes;

  eventSubType?:string;

  constructor(eventType:sceneEventTypes) {
    this.eventType=eventType;
  }
}